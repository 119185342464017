// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../libs/ID.res.js";
import * as Url from "../../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Cloudinary from "../../../../../../libs/Cloudinary.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as Routes_Resource from "../../../../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalContainer from "../../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as HivelocityCaseStudyBanner from "./components/banner/HivelocityCaseStudyBanner.res.js";
import * as NotifyBareMetalCaseStudyDownloadRequest from "../../../../../../api/requests/NotifyBareMetalCaseStudyDownloadRequest.res.js";

var hivelocity = "hivelocity";

var initialState = {
  signInModal: "Hidden"
};

function reducer(_state, action) {
  return {
          signInModal: action._0
        };
}

function HivelocityCaseStudy(props) {
  var userLoginStatus = props.userLoginStatus;
  var hivelocityCaseStudy = props.hivelocityCaseStudy;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0].signInModal;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(HivelocityCaseStudyBanner.make, {
                      title: "Hivelocity Bare Metal Case Study",
                      description: "Explore how Hivelocity continues to help companies like Klink AI reach their full potential.",
                      ctaComponent: hivelocityCaseStudy !== undefined ? JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "RedDarker",
                              onClick: (function (param) {
                                  if (userLoginStatus === "LoggedIn") {
                                    return $$Promise.wait(NotifyBareMetalCaseStudyDownloadRequest.exec(ID.toString(hivelocityCaseStudy.id), hivelocity), (function (x) {
                                                  if (x.TAG !== "Ok") {
                                                    return SentryLogger.error1({
                                                                rootModule: "HivelocityCaseStudy",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "HivelocityCaseStudy.make"
                                                              }, "HivelocityCaseStudy" + "::CaseStudyBareMetal::notifyDownload::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                  Url.visitBlank(hivelocityCaseStudy.privateDownload);
                                                  Url.visit(Routes_Resource.downloadThanks(hivelocityCaseStudy.title));
                                                }));
                                  } else {
                                    return dispatch({
                                                TAG: "ToggleSignInModal",
                                                _0: "Shown"
                                              });
                                  }
                                }),
                              children: "Download Case Study"
                            }) : null,
                      testimonialText: "\"Hivelocity dedicated servers have provided a rock solid solution to our Police DTS Cloud Application.\n                         The uptime and high performance of these dedicated servers have allowed us to concentrate on the development\n                         of our product and growth of our business!\"",
                      testimonialAuthorName: "Glenn Kuczer",
                      testimonialAuthorRole: "CEO, Systemworks, LLC",
                      testimonialAuthorImage: Cloudinary.imageUrl("v1696443358/static/glenn-kuczer")
                    }),
                match$1 === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch({
                                  TAG: "ToggleSignInModal",
                                  _0: "Hidden"
                                });
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign In to download the case study"
                      }) : null
              ]
            });
}

var make = HivelocityCaseStudy;

export {
  hivelocity ,
  initialState ,
  reducer ,
  make ,
}
/* ID Not a pure module */
