// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import CheckSvg from "../assets/check.svg";
import * as HivelocityBareMetalPricingScss from "../HivelocityBareMetalPricing.scss";

var css = HivelocityBareMetalPricingScss;

var check = CheckSvg;

function HivelocityPricingDesktop(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Smaller",
                                    className: css.headerCell
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Small"
                                        }),
                                    className: css.headerCell
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: "Medium"
                                        }),
                                    className: css.headerCell
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "Large",
                                    className: css.headerCell
                                  })
                            ],
                            className: Cx.cx([
                                  css.row,
                                  css.headerRow
                                ])
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "3.5GHz Intel E3-1270 v3"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(4 Cores)"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "3.9GHz AMD Ryzen 5600G"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(6 Cores)"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "2 x 2.1GHz Intel E5-2683 v4"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(16 Cores)"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "2.25GHz AMD EPYC 7742"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(64 Cores)"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "32GB DDR4 RAM"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "64GB DDR4 RAM"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "128GB DDR4 RAM"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "256GB RAM"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "480GB SSD"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "480GB SSD"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "960GB SSD"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "960GB SSD"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "20TB / 1Gbps"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "20TB / 1Gbps"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "100TB / 1Gbps"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "100TB / 1Gbps"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "DDoS Protection",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "DDoS Protection",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "DDoS Protection",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "DDoS Protection",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Full-Service Portal",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Full-Service Portal",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Full-Service Portal",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Full-Service Portal",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            "from",
                                            JsxRuntime.jsx("span", {
                                                  children: "$40"
                                                }),
                                            "/month"
                                          ]
                                        }),
                                    className: Cx.cx([
                                          css.cell,
                                          css.pricingCell
                                        ])
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            "from",
                                            JsxRuntime.jsx("span", {
                                                  children: "$99"
                                                }),
                                            "/month"
                                          ]
                                        }),
                                    className: Cx.cx([
                                          css.cell,
                                          css.pricingCell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            "from",
                                            JsxRuntime.jsx("span", {
                                                  children: "$189"
                                                }),
                                            "/month"
                                          ]
                                        }),
                                    className: Cx.cx([
                                          css.cell,
                                          css.pricingCell
                                        ])
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            "from",
                                            JsxRuntime.jsx("span", {
                                                  children: "$729"
                                                }),
                                            "/month"
                                          ]
                                        }),
                                    className: Cx.cx([
                                          css.cell,
                                          css.pricingCell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: Cx.cx([
                                  css.row,
                                  css.pricingRow
                                ])
                          })
                    ],
                    className: css.pricingTable
                  }),
              className: css.desktop
            });
}

var make = HivelocityPricingDesktop;

export {
  css ,
  check ,
  make ,
}
/* css Not a pure module */
