// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../../../styleguide/components/Heading/H2.res.js";
import * as React from "react";
import * as Testimonial from "../../../../../../../../styleguide/components/Testimonial/Testimonial.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ScheduleModalServices from "../../../../../../common/schedule-modal-services/ScheduleModalServices.res.js";
import * as HivelocitySignUpCallOut from "./components/sign-up/HivelocitySignUpCallOut.res.js";
import * as HivelocityCaseStudyBannerScss from "./HivelocityCaseStudyBanner.scss";
import TestimonialDatacentersSvg from "./assets/testimonial-datacenters.svg";

var css = HivelocityCaseStudyBannerScss;

var testimonialBackground = TestimonialDatacentersSvg;

function HivelocityCaseStudyBanner(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsScheduleDemoModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.wrapperBackgroundImage,
                      style: {
                        backgroundImage: "url(" + testimonialBackground + ")"
                      }
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H2.make, {
                                      className: css.title,
                                      children: props.title
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: props.description,
                                      className: css.description
                                    }),
                                props.ctaComponent
                              ],
                              className: css.ctaWrapper
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Testimonial.make, {
                                    name: props.testimonialAuthorName,
                                    role: props.testimonialAuthorRole,
                                    description: props.testimonialText,
                                    imageUrl: props.testimonialAuthorImage,
                                    classNameText: css.testimonialText,
                                    classNameAuthor: css.testimonialText,
                                    showMore: false
                                  }),
                              className: css.testimonialWrapper
                            })
                      ],
                      className: css.wrapperContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(HivelocitySignUpCallOut.make, {
                            title: "Get 1 Month Free When you Sign Up Today!",
                            buttonTitle: "Request a Demo",
                            onClick: (function (_event) {
                                setIsScheduleDemoModalOpen(function (param) {
                                      return true;
                                    });
                              })
                          }),
                      className: css.bannerContainer
                    }),
                match[0] ? JsxRuntime.jsx(ScheduleModalServices.make, {
                        onClose: (function () {
                            setIsScheduleDemoModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        title: "Schedule a Demo",
                        subtitle: "Submit the form to schedule a demo and learn more about Hivelocity bare metal solutions.",
                        buttonTitle: "Submit",
                        leadType: "HivelocityBareMetal"
                      }) : null
              ],
              className: css.wrapper
            });
}

var make = HivelocityCaseStudyBanner;

export {
  css ,
  testimonialBackground ,
  make ,
}
/* css Not a pure module */
