// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HivelocityPricingMobile from "./components/HivelocityPricingMobile.res.js";
import * as HivelocityPricingDesktop from "./components/HivelocityPricingDesktop.res.js";
import * as HivelocityBareMetalPricingScss from "./HivelocityBareMetalPricing.scss";

var css = HivelocityBareMetalPricingScss;

function HivelocityPricing(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.title,
                      children: "Hivelocity Bare Metal Pricing and Configurations "
                    }),
                JsxRuntime.jsx(HivelocityPricingDesktop.make, {}),
                JsxRuntime.jsx(HivelocityPricingMobile.make, {})
              ],
              className: css.wrapper
            });
}

var make = HivelocityPricing;

export {
  css ,
  make ,
}
/* css Not a pure module */
