// Generated by ReScript, PLEASE EDIT WITH CARE

import * as HorizontalScroll from "../../../../../../../styleguide/components/HorizontalScroll/HorizontalScroll.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HivelocityPricingColumnMobile from "./pricing-column-mobile/HivelocityPricingColumnMobile.res.js";
import * as HivelocityBareMetalPricingScss from "../HivelocityBareMetalPricing.scss";

var css = HivelocityBareMetalPricingScss;

function HivelocityPricingMobile(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(HorizontalScroll.make, {
                    children: [
                      JsxRuntime.jsx(HivelocityPricingColumnMobile.make, {
                            heading: "Smaller",
                            cpusA: "3.5GHz Intel E3-1270 v3",
                            cpusB: "(4 Cores)",
                            memory: "32GB DDR4 RAM",
                            storageA: "480GB SSD",
                            network: "20TB / 1Gbps",
                            price: "40"
                          }),
                      JsxRuntime.jsx(HivelocityPricingColumnMobile.make, {
                            heading: "Small",
                            cpusA: "3.9GHz AMD Ryzen 5600G",
                            cpusB: "(6 Cores)",
                            memory: "64GB DDR4 RAM",
                            storageA: "480GB SSD",
                            network: "20TB / 1Gbps",
                            price: "99"
                          }),
                      JsxRuntime.jsx(HivelocityPricingColumnMobile.make, {
                            heading: "Medium",
                            cpusA: "2 x 2.1GHz Intel E5-2683 v4",
                            cpusB: "(16 Cores)",
                            memory: "128GB DDR4 RAM",
                            storageA: "960GB SSD",
                            network: "100TB / 1Gbps",
                            price: "189"
                          }),
                      JsxRuntime.jsx(HivelocityPricingColumnMobile.make, {
                            heading: "Large",
                            cpusA: "2.25GHz AMD EPYC 7742",
                            cpusB: "(64 cores)",
                            memory: "256GB RAM",
                            storageA: "960GB SSD",
                            network: "100TB / 1Gbps",
                            price: "79"
                          }),
                      JsxRuntime.jsx("div", {
                            className: css.spacer
                          })
                    ],
                    className: css.horizontalScrollRow
                  }),
              className: css.mobile
            });
}

var make = HivelocityPricingMobile;

export {
  css ,
  make ,
}
/* css Not a pure module */
