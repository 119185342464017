// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../../models/User.res.js";
import * as ExclusiveServices from "../../../../styleguide/components/ExclusiveServices/ExclusiveServices.res.js";
import * as HivelocityPricing from "./components/pricing/HivelocityPricing.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HivelocityCaseStudy from "./components/case-study/HivelocityCaseStudy.res.js";
import * as JumbotronHivelocity from "./components/jumbotron/JumbotronHivelocity.res.js";
import * as ResourcesSidebarSection from "../../../resources/common/sidebar-section/ResourcesSidebarSection.res.js";
import * as HivelocityBareMetalScss from "./HivelocityBareMetal.scss";
import * as HivelocityServicesOverview from "./components/services-overview/HivelocityServicesOverview.res.js";
import * as RequestEstimateHivelocityBareMetal from "./components/request-estimate/RequestEstimateHivelocityBareMetal.res.js";
import HeaderDotsSvg from "../../common/assets/header-dots.svg";
import HivelocityHeaderLeftSvg from "./assets/hivelocity-header-left.svg";
import HivelocityHeaderRightSvg from "./assets/hivelocity-header-right.svg";
import * as ComponentsImportsLoadableJsx from "Loadable/components.imports-loadable.jsx";

var css = HivelocityBareMetalScss;

var headerDotsSvg = HeaderDotsSvg;

var bareMetalHeaderLeftSvg = HivelocityHeaderLeftSvg;

var bareMetalHeaderRightSvg = HivelocityHeaderRightSvg;

function HivelocityBareMetal$default(props) {
  var caseStudies = props.caseStudies;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(JumbotronHivelocity.make, {}),
                            className: css.backgroundImage,
                            style: {
                              backgroundImage: "url(" + bareMetalHeaderLeftSvg + "), url(" + bareMetalHeaderRightSvg + ")"
                            }
                          }),
                      className: css.jumbotronSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(HivelocityPricing.make, {}),
                            className: css.pricingAndConfigurationsSectionContainer
                          }),
                      className: css.pricingAndConfigurationsSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(RequestEstimateHivelocityBareMetal.make, {}),
                      className: css.requestEstimateBareMetalSection
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(HivelocityServicesOverview.make, {}),
                              className: css.detailsContent
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(ExclusiveServices.make, {
                                      title: "Exclusive Services",
                                      services: [
                                        {
                                          service: "Custom Tailor Hardware",
                                          description: "Fully customize your dedicated server’s hardware and software to meet your specific requirements. Add memory, NVMe drives, SSD drives, 10g+ NICs, and more."
                                        },
                                        {
                                          service: "Dedicated Backbone",
                                          description: "Get the lowest latency in the industry. Our private backbone between sites accelerates your traffic to get it closest to your end user before going out over the public internet."
                                        },
                                        {
                                          service: "Infrastructure as Code",
                                          description: "Make your DevOps teams happy and build repeatable deployment pipelines using our API and Terraform Provider."
                                        },
                                        {
                                          service: "Buckets of Free Transfer",
                                          description: "Every server comes with at least 10TB of free outbound transfer. Many come with 100TB of free transfer. All inbound transfer and private transfer between your Hivelocity servers is 100% free."
                                        },
                                        {
                                          service: "Private Networks",
                                          description: "Create VLANs between your dedicated servers and virtual machines instantly with our self-service network automation."
                                        },
                                        {
                                          service: "Free DNS Management",
                                          description: "Use our global nameservers to host your domain. Accelerate your website load times and manage your entire IT infrastructure from one easy to use panel."
                                        },
                                        {
                                          service: "IPv4 & IPv6",
                                          description: "Every server comes with multiple usable IPv4 and Ipv6 addresses. You can add as many more as you like from our self-service portal."
                                        },
                                        {
                                          service: "Free DDOS Protection",
                                          description: "We will automatically mitigate DDOS attacks on your machines using our network level DDOS protection."
                                        },
                                        {
                                          service: "Global Footprint",
                                          description: "Choose from 30+ cities across 6 continents for your dedicated server. Reach 80% of the world’s internet population in under 25ms."
                                        }
                                      ]
                                    }),
                                caseStudies.length !== 0 ? JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(ResourcesSidebarSection.make, {
                                              title: "Resources",
                                              categorySlug: "case-studies",
                                              resources: caseStudies,
                                              query: "hivelocity"
                                            }),
                                        className: css.sticky
                                      }) : null
                              ],
                              className: css.detailsSidebar
                            })
                      ],
                      className: css.detailsSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(ComponentsImportsLoadableJsx.HivelocityBareMetalCloudMap, {}),
                            className: css.map
                          }),
                      className: css.caseStudySection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(HivelocityCaseStudy.make, {
                            hivelocityCaseStudy: props.hivelocityCaseStudy,
                            userLoginStatus: User.LoginStatus.fromBool(props.isUserLoggedIn),
                            setUserData: props.setUserData
                          }),
                      className: css.caseStudySection
                    })
              ],
              className: css.wrapper
            });
}

var $$default = HivelocityBareMetal$default;

export {
  css ,
  headerDotsSvg ,
  bareMetalHeaderLeftSvg ,
  bareMetalHeaderRightSvg ,
  $$default as default,
}
/* css Not a pure module */
