import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from '../../../../../bundles/Client/common/actions/userActions';
import { fromJs } from '../../../../../rescript/models/User.res.js';

import HivelocityBareMetal from './HivelocityBareMetal.res.js';

const mapStateToProps = state => ({
  isUserLoggedIn: !!state.user.id,
  userData: fromJs(state.user),
});

const mapDispatchToProps = dispatch => ({
  setUserData: userData => {
    dispatch(setUserData(userData));
  },
});

const HivelocityBareMetalContainer = connect(mapStateToProps, mapDispatchToProps)(HivelocityBareMetal);

export default props => (
  <ReduxProvider {...props}>
    <HivelocityBareMetalContainer
      hivelocityCaseStudy={props.hivelocityCaseStudy}
      caseStudies={props.caseStudies}
    />
  </ReduxProvider>
);
