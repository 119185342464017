// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../../../../../styleguide/components/Heading/H2.res.js";
import * as Button from "../../../../../../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HivelocitySignUpCallOutScss from "./HivelocitySignUpCallOut.scss";

var css = HivelocitySignUpCallOutScss;

function HivelocitySignUpCallOut(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(H2.make, {
                                  className: css.title,
                                  children: props.title
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Button.make, {
                                        size: "MD",
                                        color: "RedDarker",
                                        className: css.buttonAction,
                                        onClick: props.onClick,
                                        children: props.buttonTitle
                                      }),
                                  className: css.buttonColumn
                                })
                          ],
                          className: css.bannerRow
                        }),
                    className: css.wrapperInner
                  }),
              className: css.wrapper
            });
}

var make = HivelocitySignUpCallOut;

export {
  css ,
  make ,
}
/* css Not a pure module */
